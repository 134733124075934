import { css } from '@emotion/react';
import { COLOR, FONTS, FONT_FAMILY } from '@/app/common/style/variables';
import { rem } from './functions';
import {
  phabletAndBelow,
  tablet,
  tabletAndBelow,
  tabletDown,
  desktop,
  desktopLarge,
  wide,
} from './mixins';

export const btn = css`
  display: inline-block;
  border-radius: 28px;
  background: ${COLOR.white};
  border: 1px solid ${COLOR.woodSmoke3};
  text-decoration: none;
  cursor: pointer;
  padding: 10px 23px;
  text-align: center;
  font-size: ${rem(16)};
  line-height: 24px;
  letter-spacing: 0;
  min-height: 56px;
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;

  &:hover {
    border-width: 1px;
    background: ${COLOR.woodSmoke5};
  }

  &:focus-visible {
    border: 3px solid ${COLOR.woodSmoke3};
    padding: 8px 21px;
    outline: none;
    box-shadow: none;
  }

  &:active {
    border: none;
    padding: 11px 24px;
    background: ${COLOR.woodSmoke2};
    color: ${COLOR.white};
  }

  &.wide {
    max-width: 327px;
    width: 100%;
  }

  &.menu {
    font-size: ${rem(22)};
    line-height: 30px;
    letter-spacing: 0.5px;

    &:focus-visible {
      border: 3px solid ${COLOR.palmLeaf};
      padding: 8px 21px;
    }

    &:active {
      border: none;
      padding: 11px 24px;
    }
  }

  &.alt {
    color: ${COLOR.woodSmoke5};
    border: 1px solid ${COLOR.woodSmoke5};
    background-color: ${COLOR.woodSmoke1};

    &:hover:not(:focus-visible) {
      background-color: ${COLOR.woodSmoke2};
    }

    &:focus-visible {
      background-color: none;
      border: 3px solid ${COLOR.woodSmoke5};
    }

    &:active {
      border: none;
      background-color: ${COLOR.woodSmoke3};
    }
  }

  &.calendly {
    font-family: ${FONTS.gtEestiProDisplay};
    font-size: ${rem(16)};
    font-weight: ${FONTS.regular};
    line-height: 24px;
    padding: 16px 37px 16px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    background-color: ${COLOR.white};
    border: 1px solid ${COLOR.woodSmoke3};
    box-sizing: border-box;
    border-radius: 50px;
    height: 56px;
    width: 245px;
    margin-bottom: 24px;

    ${desktop(css`
      width: fit-content;
    `)}

    ${tabletDown(css`
      padding: 16px 35px 16px 8px;
      width: 152px;
      height: 56px;
    `)}

    ${phabletAndBelow(css`
      width: fit-content;
    `)}

    &::before {
      content: '';
      display: inline-block;
      background-image: url('/assets/images/contact/calendly-logo.svg');
      background-repeat: no-repeat;
      width: 44.76px;
      height: 40px;
      vertical-align: middle;
      margin-right: 9px;
    }

    &.work-case {
      width: auto;
    }
  }
`;

export const link = css`
  text-decoration: none;
  position: relative;
  color: ${COLOR.woodSmoke1};
  font-family: ${FONT_FAMILY.gtEestiProDisplay};
  padding: 4px 0;
  font-size: ${rem(16)};
  line-height: 24px;
  letter-spacing: 0;
  font-weight: ${FONTS.medium};
  letter-spacing: 0.5px;
  border-bottom: 1px solid transparent;

  &:has(b) {
    text-decoration: underline;

    b {
      font-weight: 500;
    }

    &:hover {
      border-bottom: none !important;
    }

    &::after {
      content: '';
      display: inline-block;
      background-image: none;
      background-repeat: no-repeat;
      background-position: right;
      width: auto;
      height: auto;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }

  &::after {
    content: '';
    display: inline-block;
    background-image: url('/assets/images/arrow-right.svg');
    background-repeat: no-repeat;
    background-position: right;
    width: 17px;
    height: 14px;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto 0 0 10px;
  }

  &:hover:not(:focus-visible),
  &:active {
    transition: border 1s;
    border-bottom: 1px solid ${COLOR.woodSmoke1};
  }

  &:focus-visible {
    border: 3px solid ${COLOR.woodSmoke3};
    padding: 8px 56px 8px 21px;
    border-radius: 24px;

    &::after {
      right: 24px;
    }
  }

  &[target]::after {
    width: 21px;
    height: 21px;
    background-image: url('/assets/images/arrow-external.svg');
  }

  &[href^='#']::after {
    width: 14px;
    height: 17px;
    background-image: url('/assets/images/arrow-jump.svg');
  }

  &.back {
    padding: 4px 0;

    &::after {
      content: none;
    }

    &::before {
      content: '';
      display: inline-block;
      background-image: url('/assets/images/arrow-left.svg');
      width: 17px;
      height: 14px;
      margin-right: 11px;
    }

    &:focus-visible {
      padding: 8px 24px;
    }
  }

  &.alt {
    color: ${COLOR.woodSmoke5};

    &::after {
      background-image: url('/assets/images/arrow-right-alt.svg');
    }

    &[target]::after {
      background-image: url('/assets/images/arrow-external-alt.svg');
    }

    &[href^='#']::after {
      background-image: url('/assets/images/arrow-jump-alt.svg');
    }

    &.back::before {
      background-image: url('/assets/images/arrow-left-alt.svg');
    }

    &:focus-visible {
      border-color: ${COLOR.woodSmoke5};
    }

    &:hover:not(:focus-visible) {
      border-bottom: 1px solid ${COLOR.woodSmoke5};
    }
  }
`;

export const filterPill = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 115px;
  width: 105%;
  justify-content: center;

  ${tabletAndBelow(css`
    height: auto;
    justify-content: flex-start;
  `)}

  ${tablet(css`
    padding: 20px 0;
  `)}


  ${phabletAndBelow(css`
    margin-bottom: -8px;
  `)}

  .filter-pill {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    margin-right: 12px;

    ${wide(css`
      &:not(:last-child) {
        margin-right: 16px;
      }
    `)}

    ${desktopLarge(css`
      &:not(:last-child) {
        margin-right: 16px;
      }
    `)}

    ${desktop(css`
      &:not(:last-child) {
        margin-right: 16px;
      }
    `)}

    ${tablet(css`
      &.checked:last-child {
        margin-right: 12px;
      }
      &.checked:not(:last-child) {
        margin-right: 12px;
      }
      &:not(:last-child) {
        margin-right: 12px;
      }
      &:not(.checked):not(:last-child) {
        margin-right: 12px;
      }

      &:not(.checked):not(:last-child) + div {
        margin-right: unset;
      }
    `)}

    ${phabletAndBelow(css`
      padding-bottom: 0;
      margin-bottom: 8px;

      &.checked:not(:last-child) {
        margin-right: 12px;
      }

      &:not(:last-child) {
        margin-right: 12px;
      }

      &:not(.checked):not(:last-child) {
        margin-right: 12px;
      }

      &:not(.checked):not(:last-child) + div {
        margin-right: 12px;
      }
    `)}

    > input {
      display: none;

      &:not(:checked) + label {
        background-color: ${COLOR.woodSmoke5}; //$color-wood-smoke-5;
        border-color: ${COLOR.woodSmoke5}; //$color-wood-smoke-5;

        &::before {
          display: none;
        }
      }
    }

    > label {
      line-height: 24px;
      color: ${COLOR.woodSmoke1}; //$color-wood-smoke-1;
      padding: 4px 20px 4px 26px;
      border: 1px solid ${COLOR.woodSmoke1}; //$color-wood-smoke-1;
      border-radius: 50px;
      cursor: pointer;
      font-size: ${rem(16)};
      letter-spacing: 0.5px;
      position: relative;
      margin-bottom: 0;
      width: max-content;

      &:not(.checked) {
        padding-left: 20px;
      }

      ${tabletAndBelow(css`
        font-size: ${rem(14)};
        line-height: 18px;
        letter-spacing: 0.5px;
        padding-top: 6px;
        padding-bottom: 6px;

        &.checked {
          padding: 6px 10px 6px 25px;
        }

        &:not(.checked) {
          padding-left: 10px;
          padding-right: 10px;
        }
      `)}

      ${phabletAndBelow(css`
        &.checked {
          padding: 6px 9px 6px 24px;
        }
      `)}

      &::before {
        content: '';
        display: block;
        background-image: url('/assets/images/check-mark.svg');
        position: absolute;
        width: 12px;
        height: 12px;
        left: 8px;
        top: 9px;
        bottom: 0;
        background-repeat: no-repeat;
        fill: ${COLOR.woodSmoke1}; //$color-wood-smoke-1;
      }

      &:hover {
        background-color: ${COLOR.white}; // $color-white-smoke;
      }

      &:focus {
        padding: 3px 19px 3px 25px;
        border: 2px solid ${COLOR.woodSmoke1}; // $color-wood-smoke-1;
      }
    }
  }
`;

const Buttons = css`
  button,
  .btn {
    ${btn};
  }

  .link:not(.btn) {
    ${link};
  }

  .filters:not(.btn),
  .filters {
    ${filterPill};
  }
`;

export default Buttons;
